@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/Futura/Futura\ Book.otf") format("opentype");
}
@font-face {
  font-family: 'medium';
  font-weight: 400;
  src: url("./assets/font/Futura/Futura\ Medium.otf") format("opentype");
}
@font-face {
  font-family: 'bold';
  font-weight: 400;
  src: url("./assets/font/Futura/Futura\ Bold.otf") format("opentype");
}

  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  * {
    color: rgb(0,35,59);
  }