@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider-container {
    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
  
    .slick-prev,.slick-next {
      height: auto;
    }
    .slick-prev {
      @media (max-width: 768px) {
        left: -5px;
      }
      left: -40px;
    }
  
    .slick-next {
      @media (max-width: 768px) {
        right: -5px;
      }
      right: -40px;
    }
    .slick-prev, .slick-next {
      @media (max-width: 768px) {
        width: 10px;
      }
      width: 20px;
    }
  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-track {
    margin: 0;
  }
  
  .content {
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  
    &:hover {
      transform: scale(1.05);
    }
  
    &--blue {
      background: #002855;
      color: white;
    }
  
    &--gray {
      background: #f7f7f7;
      color: black;
    }
  
    .icon {
      font-size: 40px;
      margin-bottom: 10px;
    }
  
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }